import styles from "./index.module.scss";

export default function ThemedLink({
  href,
  openNewTab = true,
  iconSize = 14,
  className,
  children,
}) {
  return (
    <a
      href={href}
      className={`${styles["themed-link"]} ${className}`}
      target={openNewTab ? "_blank" : ""}
      rel={openNewTab ? "noopener noreferrer" : ""}
    >
      <span>{children}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-facebook"
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
      </svg>
    </a>
  );
}
