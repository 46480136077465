const IRAS = {
  jobTitle: "Part-time Software Developer",
  company: "Inland Revenue Authority of Singapore",
  duration: "Dec 2020 - Jan 2021",
  description:
    "Developed features and engineered input categories in a Chatbot Project to automate personalized interactions with tax payers",
  jobscope: [
    "Integrated training data and responses into Google DialogFlow for tax filing feature",
    "Implemented Typescript web hooks with NodeJS to interact with backend",
  ],
  funFact: "It was my first professional experience as a software developer.",
};

export default IRAS;
